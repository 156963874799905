import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import SEO from "../components/layout/seo";

import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Container } from 'react-bootstrap';

// import jobsBgImage from '../images/pages/jobs/jobs-hero.png';
// import workingBgImage from '../images/pages/jobs/working.png';
import jodieImage from '../images/pages/jobs/jodie.png';

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
}


const JobsPage = ({ data }) => {
  const { pageTitle, 
          metaTitle, 
          workingAtEventaBgImage, 
          workingAtEventaText, 
          jobsBgImage 
  } = data.contentfulJobs;

  return (
    <Layout>
      <SEO title={metaTitle} />
        <div className="hero-shallow" style={{backgroundImage:`url(${jobsBgImage.file.url})`}}>
          <div className="hero-overlay"></div>
            <Container>
              <Row>
                <Col md={12}>
                  <div className="hero-text-container">
                    <h1>{pageTitle}</h1>
                  </div>
                </Col>
              </Row>
            </Container>
        </div>
        <div className="vacancies-outer full-width-layout">
          <Container fluid={true}>
            <h2>Our vacancies</h2>
            <div className="job-item-outer">
              <Row>
                <Col md={8}>
                  <p>Finance Manager</p>
                  <p>Part-time Maternity cover</p>
                </Col>
                <Col md={4}>
                  <a target="_blank" rel="noopener noreferrer" className="main-button" href="https://www.thestagcompany.com/job-vacancies">View</a>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div className="work-at-eventa" style={{backgroundImage:`url(${workingAtEventaBgImage.file.url})`}}>
          <div className="hero-overlay"></div>
          <Container>
            <Row>
              <Col md={12}>
                {documentToReactComponents(workingAtEventaText.json, options)}
              </Col>
            </Row>
          </Container>
        </div>
        <div className="testimonial">
        <h2>What our staff say</h2>
          <Container className="testimonial-inner">
            <div className="staff-item">
              <img className="profile-photo" alt="profile" src={jodieImage} />
              <h5>“My time at Hen Heaven and The Stag Company has been the peak of my working career"</h5>
              <p className="staff-testimonial"><i>I have grown and developed hugely in my three and half years here and have had the privilege of receiving and achieving two promotions in a very short time. It has been key in developing my skills and I have been supported massively in my journey via both external and in-house training. The company has been a great foundation for both my professional and personal life here in Brighton.”</i></p>
              <p className="staff-name">Jodie Jeffrey</p>
              <p className="staff-position">Supplier Relations</p>
            </div>
          </Container>
        </div>
    </Layout>
  );
};

export default JobsPage;

export const pageQuery = graphql`
  query {
    contentfulJobs {
      jobsBgImage {
        file {
          url
        }
      }
      workingAtEventaBgImage {
        file {
          url
        }
      }
      workingAtEventaText {
        json
      }
      metaTitle
      pageTitle
      slug
    }
  }
`;